import Vue from 'vue'
import VueRouter from 'vue-router'
import { createWebHistory } from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/contact-us',
    name: 'contact',
    component: ()=>import('../views/Contact-us/contact-us')
  },
  {
    path: '/',
    name: 'index',
    component: ()=>import('../views/Index/index')
  },
  {
    path: '/news-detail',
    name: 'news-detail',
    component: ()=>import('../views/News-detail/news-detail')
  },
  {
    path: '/people',
    name: 'people',
    component: ()=>import('../views/People/people')
  },
  {
    path: '/personal-page',
    name: 'personal-page',
    component: ()=>import('../views/Personal-page/personal-page')
  },
  {
    path: '/plog',
    name: 'plog',
    component: ()=>import('../views/Plog/plog')
  },
  {
    path: '/publications',
    name: 'publication',
    component: ()=>import('../views/Publications/publications')
  },
  {
    path: '/tagnews',
    name: 'tagnews',
    component: ()=>import('../views/Tagnews/tagnews')
  },
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  // history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  // 解决页面跳转不返回顶部的问题
  scrollBehavior(to,from,saveTop){
    if(saveTop){
      return saveTop;
    }else{
      return {x:0,y:0}
    }
  },
})

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

export default router

