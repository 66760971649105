<template>
  <div id="app" v-if="isRouterAlive">
    <router-view />
    <!-- contact-area -->
    <!-- <section class="homeContact homeContact__style__two">
      <div class="container">
        <div class="homeContact__wrap">
          <div class="row">
            <div class="col-lg-6">
              <div class="section__title">
                <span class="sub-title"> >-- Say hello</span>
                <h2 class="title">
                  Any questions? Please <br />
                  contact us
                </h2>
              </div>
              <div class="homeContact__content">
                <p>Service Computing Laboratory</p>
                <h2 class="mail">
                  <a href="mailto:sclab@cup.edu.cn">sclab@cup.edu.cn</a>
                </h2>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="homeContact__form">
                <form>

                  <input type="text" v-model="formMsg.name" placeholder="Enter name*" />
                  <input type="email" v-model="formMsg.email" placeholder="Enter mail*" />
                  <input type="number" v-model="formMsg.phone" placeholder="Enter number*" />
                  <textarea
                    name="message"
                    v-model="formMsg.message" 
                    placeholder="Enter Massage*"
                  ></textarea>
                </form>
                <button @click="sendMessage" class="sendMessage">Send Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- contact-area-end -->

    <!-- Footer-area -->
    <footer class="footer_bottom">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-4">
            <div class="footer__widget">
              <div class="fw-title">
                <h5 class="sub-title">Contact us</h5>
                <!-- title是大写 -->
                <h4 class="title"> email</h4>
                
              </div>
              <div class="footer__widget__text">
                <!-- <p>SCLAB 欢迎您</p> -->
                <a id="sendmail" href="mailto:sclab@cup.edu.cn">email: sclab@cup.edu.cn</a>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6">
            <div class="footer__widget">
              <div class="fw-title">
                <h5 class="sub-title">our address</h5>
                <h4 class="title">Beijing, China</h4>
              </div>
              <div class="footer__widget__address">
                <p>
                  China University of Petroleum-Beijing <Br />
                  No.18 Fuxue Road, Changping District, Beijing 102249, China
                </p>
                <!-- <a href="2021211248@studen.cup.edu.cn" class="mail"
                  >cup.edu.cn</a> -->
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-6">
            <div class="footer__widget">
              <div class="fw-title">
                <h5 class="sub-title">Follow us</h5>
                <h4 class="title">socially connect</h4>
              </div>
              <div class="footer__widget__social">
                <a  href="https://www.douyin.com/user/MS4wLjABAAAACQS7ACdVPQN7SiHMQHRuA8YE5wf0aE_n9vvJZrDZobc">
                  douyin:
                  408安保大队的故事  <br />
                </a>
                <!-- 下边的标签好像都是失效了？被覆盖了？ -->
                <!-- <ul class="footer__social__list">
                  <li>
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-behance"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-instagram"></i></a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
        <div class="copyright__wrap">
          <div class="row">
            <div class="col-12">
              <div class="copyright__text text-center">
                <p>
                  SCLAB - 
                  <a target="_blank" href="https://beian.miit.gov.cn/"
                    >京ICP备19055779号
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer-area-end -->
  </div>
</template>
<script>
import { MessageBox } from 'element-ui';

export default {
  components: {
    MessageBox
  },
  name : "app",
  provide(){
      return{
        reload:this.reload
      }
    },
    data(){
      return{
        formMsg:{
          name:'',
          email:'',
          phone:'',
          message:'',
        },
        isRouterAlive:true
      }
    },

    methods:{
      reload(){
        this.isRouterAlive = false;
        this.$nextTick(function () {
          this.isRouterAlive = true;
        });
      },
      sendMessage() {
        // this.$alert('功能待开发，敬请期待', '发送邮件', {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     // this.$message({
        //     //   type: 'info',
        //     //   message: `action: ${ action }`
        //     // });
        //   }
        // });
        if(this.formMsg.email === "" || this.formMsg.name === "" || this.formMsg.phone === "" || this.formMsg.message === ""){
          this.$alert("Please fill out the form completely.")
        }
        else{
          // console.log("this.form.message",this.formMsg)
          var email = document.createElement("a");
          email.href = "mailto:sclab@cup.edu.cn?subject=sclab.cup.edu.cn: name:"+ this.formMsg.name + ', phone:' +this.formMsg.phone + "&body=my email:"+ this.formMsg.email + ', Message:' + this.formMsg.message   ;
          email.click();
        }

        
      }
    },
}
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?43cbc2335643d9e38a5f1078fb35aaf0";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>


<style scoped>
@import url(https://fonts.googlefonts.cn/css2?family=Nunito:ital,wght@0,400;0,600;0,700;1,400&display=swap);
/* 这是底部导航栏的代码 */
@import "../public/css/style-footer.css";
/* @import "../public/publication/css/style.css"; */
@import "../public/news/css/animate.css";
@import "../public/news/css/animsition.min.css";
@import "../public/news/css/util.min.css";
@import "../public/news/css/stylesheet.css";
@import "../public/contact/css/style.min.css";
@import "../public/contact/css/vendor/vendor.min.css";
@import "../public/css/plugins/plugins.min.css";
@import "../public/css/style-starter.css";

      /* 这是为了自己加的底部的导航事件 */
      .sendMessage  {
        position: absolute;
        right: 0;
        bottom: 14px;
        border: none;
        color: #191B1E;
        text-transform: uppercase;
        background: transparent;
        padding: 0;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1; }
        .sendMessage::after {
          content: "\f1d8";
          font-family: "Font Awesome 5 Pro";
          font-weight: 700;
          margin-left: 8px; }
        .sendMessage:hover {
          color: #0054FF; }


</style>