import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css'; 
import VueClipboard from 'vue-clipboard2'


Vue.config.productionTip = false
Vue.prototype.axios=axios
VueClipboard.config.autoSetContainer = true // add this line

Vue.use(ElementUI); 
Vue.use(VueClipboard)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
